/**
 * @namespace userGeoPositionCache
 */

const CACHE_KEY = 'user-geoposition';

interface CachedGeoPosition {
    latitude: number;
    longitude: number;
    date: Date;
}

/**
 * method to save device geoposition to session storage
 * @method saveUserGeoPositionToCache
 * @memberof userGeoPositionCache
 * @param {number} latitude
 * @param {number} longitude
 * @returns {void}
 * @author Aliaksandr Dzemchyk <aliaksandr.dzemchyk@inspirebrands.com>
 * @added 2023-03-09
 * @version 1.0
 */
export function saveUserGeoPositionToCache(latitude: number, longitude: number): void {
    if (Number.isFinite(latitude) && Number.isFinite(longitude)) {
        sessionStorage.setItem(CACHE_KEY, JSON.stringify({ latitude, longitude, date: new Date() }));
    }
}

/**
 * @typedef CachedGeoPosition
 * @property {number} latitude
 * @property {number} longitude
 * @property {Date} date date when when entry was added to cache
 */
/**
 * method to retrieve cached device geoposition from session storage
 * @method getUserGeoPositionFromCache
 * @memberof userGeoPositionCache
 * @param {number} latitude
 * @param {number} longitude
 * @returns {CachedGeoPosition | null}
 * @author Aliaksandr Dzemchyk <aliaksandr.dzemchyk@inspirebrands.com>
 * @added 2023-03-09
 * @version 1.0
 * @example
 * saveUserGeoPositionToCache(0, 0);
 * getUserGeoPositionFromCache(); // return { latitude: 0, longitude: 0, date: date object when entry was added to cache }
 */
export function getUserGeoPositionFromCache(): CachedGeoPosition | null {
    const cached = JSON.parse(sessionStorage.getItem(CACHE_KEY));

    if (!cached) {
        return null;
    }

    return {
        longitude: cached.longitude,
        latitude: cached.latitude,
        date: new Date(cached.date),
    };
}
