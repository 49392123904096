import React, { FC } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'classnames';

import styles from './loader.module.css';

interface ILoaderProps {
    size?: number | string;
    className?: string;
}

const Loader: FC<ILoaderProps> = ({ size, className }): JSX.Element => {
    return (
        <CircularProgress
            classes={{ root: classnames(styles.loader, className) }}
            size={size}
            aria-label="content loading"
        />
    );
};

export default Loader;
