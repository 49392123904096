const CONSTANTS = {
    ALL_LOCATION: 'All Locations',
    BACK_TO_TOP: 'Back to Top',
    CLOSE: 'Close',
    SAVE: 'Save',
    SAVE_CLOSE: 'Save & Close',
    REDEEM: 'Redeem',
    REDEEM_LOWERCASE: 'redeem',
    VIEW_DEAL: 'view deal',
    CALORIES_DYNAMIC: '{cal} cal',
    EXPIRES_DATE_DYNAMIC: 'Expires {expiresDate}',
    LEGAL_TERM_CONDITION: 'Legal terms and conditions',
    NOT_AVAILABLE: 'Not Available',
    LOGOUT: 'Logout',
    ORDER: 'ORDER',
    VIEW_ALL: 'VIEW ALL',
    CONTENT_WARNING_TEXT:
        'CONTENT CREATOR WARNING: Please create a product with id = default in Contentful for default image',
    TEMPORARY_UNAVAILABLE: 'Temporarily Unavailable',
    REMOVE: 'Remove',
    REMOVE_GIFT_CARD: 'Remove Gift Card',
    APPLY_GIFT_CARD: 'Apply Gift Card',
    EDIT: 'Edit',
    MAKE_DEFAULT: 'Make default',
    FULL_NUTRITION_UNAVAILABLE_TEXT: 'Full nutrition details are not available for this item.',
    VIEW_FULL_NUTRITION: 'View full nutrition & allergen information',
    CERTIFICATE_DETAILS: 'Certificate details',
    ORDER_ONLINE: 'Order online',
    EXPIRES_BY_DYNAMIC: 'Expires by {formattedExpirationDate}',
    PAYMENT: 'Payment',
    REWARD_CERTIFICATE: 'Reward Certificate(s)',
    CARD_NUMBER: 'Card Number',
    SUB_TOTAL: 'Subtotal',
    TOTAL: 'Total',
    SKIP_TO_MAIN_CONTENT: 'skip to main content',
    START_TO_ORDER: 'Start an Order',
    LIMITED_TO_DEAL: 'Limited to 1 deal per order',
    VIEW_ALL_LOWERCASE: 'view all',
    SOME_ITEMS_UNAVAILABLE: 'Some items unavailable',
    MAIN_SCREEN: 'main screen',
    ZERO_BALANCE_GIFT_CARD_ERROR_TEXT:
        'We’re sorry, we cannot add gift cards with $0 balance. Please add a card with a balance to make a purchase.',
    GENERAL_GIFT_CARD_ERROR_TEXT: 'We cannot add the gift card. Please try again later.',
    GIFT_CARD_ALREADY_EXISTS_ERROR_TEXT: 'This gift card already exists. Please update gift cards from My Account',

    /** Molecules */
    OFFER_DESCRIPTION_APPLICABLE:
        'You haven’t fulfilled the requirements for your {dealName} deal. The deal will not be redeemed. Are you sure you want to continue to checkout?',
    OFFER_DESCRIPTION_NON_APPLICABLE:
        'The {dealName} deal is not available at this location. The deal will not be redeemed. Are you sure you want to continue to checkout?',
    ADD_MORE_ITEMS: 'Add More Items',
    GO_BACK: 'Go Back',
    WE_SORRY: "We're Sorry...",
    NOTIFICATION_CHANGE_MODAL_DESCRIPTION:
        'Looks like the following items are not currently available at this location. Prices may vary by location and will automatically update at checkout.',
    CONTINUE_MISSING_TEXT: 'Continue With Missing Items',
    EDIT_YOUR_ORDER: 'Edit Your Order',
    ARE_YOUR_SURE_TEXT: 'are you sure you want to',
    NOTIFICATION_SETTINGS: 'Notification Settings',
    DEALS: 'deals',
    NOTIFICATION_ON_NEW: 'Notifications on new and expiring deals',
    WE_HATE_TO_SEE_YOU_GO: 'We hate to see you go! Subscribe anytime again.',
    UNSUBSCRIBE: 'unsubscribe?',
    SUBMIT: 'Submit',
    NO_RETURN_MY_ACCOUNT: 'No, return to my accounts',
    UNSUBSCRIBE_ME_TEXT: 'Unsubscribe me from your email list',
    TO_SEE_ALL_ITEM: 'to see all items offered in your area',
    SELECT_A_LOCATION: 'Select a location',
    ACTIVE_OFFER: 'ACTIVE OFFER',
    ACTIVE_REWARD: 'ACTIVE REWARD',
    CANCELLATION_PENDING: 'CANCELLATION PENDING',
    PENDING_REWARD_CERTIFICATE_TEXT:
        'This Reward Certificate is pending cancellation and cannot be used. Your point total will be updated to reflect points refund within 24 hours.',
    DANE_IN_ORDER: 'Dine-In & Order Reward',
    GET_TO_BUFFALO_TEXT: 'Get to Buffalo Wild Wings and order your reward.',
    GIVE_THE_SERVER_TEXT: 'Give the server your member number before paying.',
    REDEMPTION_INCLUDE_TEXT:
        'Redemption includes one order of {title}. This item may not be available for redemption in all Buffalo Wild Wings Locations. Please check with your location for availability.',
    REWARD_REDEEM: 'Reward has been redeem. Find it in your account',
    MEMBER_NUMBER_DYNAMIC: 'Member Number: {memberNumber}',
    UNABLE_CANCEL_ORDER: 'We’re unable to cancel your order. Your order is currently being prepared.',
    ENCOUNTERING_SOME_TECHNICAL_ISSUE: 'We’re encountering some technical issues. Please try again later.',
    CANCEL_YOUR_CURRENT_ORDER: 'Cancel Your Current Order?',
    CANCEL: 'Cancel',
    DONT_CANCEL: 'Don’t Cancel',
    ARE_YOU_WANT: 'Are you sure you want to',
    YES_CANCEL: 'Yes, Cancel',
    CONNECT_ISSUE: 'Connect Issues',
    OH_NO: 'Oh No...',
    ORDER_STATUS: 'Order Status',
    CONTACT_CUSTOMER_SERVICE: '{message}. Please contact Customer Service {link} or contact us.',
    CONTACT_US: '{message} {link} or contact us.',
    SOMETHING_WHEN_WRONG: 'Something went wrong. Please contact Customer Service {link} or try again later.',

    /** Delete Account */
    DELETE_ACCOUNT_TITLE: 'Delete My Account',
    DELETE_ACCOUNT_GIFT_CARD_ERROR_MAIN_TEXT_1: 'Thank you for your request!',
    DELETE_ACCOUNT_GIFT_CARD_ERROR_MAIN_TEXT_2:
        'It looks like there is at least one gift card tied to your account. Before we delete your account we need to issue you a gift card. Please contact customer service to resolve this issue.',
    DELETE_ACCOUNT_GIFT_CARD_ERROR_PHONE_NUMBER_BLOCK_FIRST:
        'It looks like there is at least one gift card tied to your account. Before we delete your account we need to issue you a gift card. Please contact customer service at',
    DELETE_ACCOUNT_GIFT_CARD_ERROR_PHONE_NUMBER_BLOCK_LAST: 'to resolve this issue.',
    DELETE_ACCOUNT_GIFT_CARD_ERROR_PHONE_NUMBER_DEFAULT: null,
    DELETE_ACCOUNT_GIFT_CARD_ERROR_PHONE_NUMBER_SDI: '866-657-6642',
    DELETE_ACCOUNT_GIFT_CARD_ERROR_BUTTON_TEXT: 'OK',
};

export { CONSTANTS };
