import { NAV_LINKS } from './navigationLinks.constants';

export const logoDesktop = undefined;
export const titleStoreNotSelected = 'Find an Arby’s';
export const SHOULD_SHOW_DEALS_COUNTER = true;
export const SHOULD_SHOW_NAV_MENU_SIGN_IN = true;
export const SHOULD_SHOW_NAV_MENU_ACTIONS_WITHOUT_LOCATION = true;
export const SHOULD_SHOW_NAV_MENU_BAG_COUNTER = true;
export const SHOULD_HIDE_START_ORDER_BTN_WHEN_ITEMS_IN_BAG = true;
export const SHOULD_SHOW_NAV_MENU_ACCOUNT_INFO = true;
export const IS_LINKS_IN_LOWER_CASE = false;
export const NAVIGATION_MENU_BAG_TEXT = 'Shopping Bag';
export const SHOULD_HIDE_START_ORDER_ON_LOCATION_DETAILS_PAGE = false;
export const SHOULD_CHANGE_COLOR = false;
export const NAVIGATION_LINKS_BLACK_LIST: string[] = [NAV_LINKS.ORDERS];
