import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import classnames from 'classnames';

import styles from './checkbox.module.css';

export interface IStyledCheckbox {
    onClick?: (p: string) => void;
    onKeyUp?: () => void;
    size?: 'small' | 'standard' | 'large';
    selected?: boolean;
    disabled?: boolean;
    fieldName: string;
    ariaLabel?: string;
    className?: string;
    tabIndex?: number;
    dataTestId?: string;
}

export default function Checkbox(props: IStyledCheckbox): JSX.Element {
    const {
        onClick,
        onKeyUp,
        selected = false,
        disabled = false,
        fieldName,
        ariaLabel,
        className,
        size = 'standard',
        tabIndex,
        dataTestId,
    } = props;
    const handleOnClick = () => {
        if (typeof onClick === 'function' && !disabled) {
            onClick(fieldName);
        }
    };

    const handleOnKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (onKeyUp && event.key === 'Enter') {
            onKeyUp();
        }
    };

    return (
        <div
            role="checkbox"
            className={classnames(className, styles.checkbox, styles[size], {
                [styles.checkboxSelected]: selected,
                [styles.checkboxDisabled]: disabled,
            })}
            {...(ariaLabel && { 'aria-label': ariaLabel })}
            onClick={handleOnClick}
            onKeyUp={handleOnKeyUp}
            aria-checked={selected}
            tabIndex={tabIndex || 0}
            data-testid={dataTestId}
        >
            <CheckIcon className={styles.checkIcon} />
        </div>
    );
}
