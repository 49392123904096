import { useMemo } from 'react';
import { useBag } from '../../redux/hooks';
import { useBagRenderItems } from './useBagRenderItems';

export default function useBagAvailableItemsCounter(): number {
    const view = useBagRenderItems();

    const { entriesMarkedAsRemoved } = useBag();
    const availableItemsInBag = useMemo<number>(() => {
        const finalQuantity =
            view &&
            view
                .filter((itemAvailable) => itemAvailable?.isAvailable) // filter only Available Item on Bag
                .filter((item) => entriesMarkedAsRemoved && !entriesMarkedAsRemoved.includes(item?.entry?.lineItemId)) // filter temporarily removed item on Bag
                .map((item) => item?.entry?.quantity)
                .reduce((acc, curr) => acc + curr, 0); // calculate the quantity of the unit of a particular item on Bag
        return finalQuantity;
    }, [view, entriesMarkedAsRemoved]);

    return availableItemsInBag;
}
