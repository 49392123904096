import { useEffect, useState } from 'react';
import { getHaversineDistance } from '../../lib/locations';
import { saveUserGeoPositionToCache, getUserGeoPositionFromCache } from '../helpers/userGeoPositionCache';
import { Coordinates } from '../services/locationService';

const options = {
    enableHighAccuracy: true,
    maximumAge: 10000,
    timeout: 5000,
};

interface IUseWatchDistanceHook {
    distance: number;
    loading: boolean;
    deviceCoords: Coordinates;
}

/**
 * Tracks the distance between device coords and passed destination coords
 * @param {number} destinationLatitude
 * @param {number} destinationLongitude
 * @returns {IUseWatchDistanceHook} distance in miles between device coords and destination coords and null if device geolocation is not available
 * @example
 * // returns distance calculated based on device location
 * const { distance } = useWatchDistance(52.0961155, 23.7408617);
 */
const useWatchDistance = (destinationLatitude?: number, destinationLongitude?: number): IUseWatchDistanceHook => {
    const [distance, setDistance] = useState<number>(null);
    const [deviceCoords, setDeviceCoords] = useState<Coordinates>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let id: number = null;
        if (
            navigator?.geolocation?.watchPosition &&
            Number.isFinite(destinationLatitude) &&
            Number.isFinite(destinationLongitude)
        ) {
            setLoading(true);
            id = navigator.geolocation.watchPosition(
                (position) => {
                    const {
                        coords: { latitude, longitude },
                    } = position;
                    setLoading(false);

                    saveUserGeoPositionToCache(latitude, longitude);

                    setDistance(getHaversineDistance(destinationLatitude, destinationLongitude, latitude, longitude));
                    setDeviceCoords({ lat: latitude, lng: longitude });
                },
                (err) => {
                    // chromium browsers sometimes return timeout error when we request geoposition multiple times on the page
                    // in that case retrieve last saved position from cache
                    if (err.code === err.TIMEOUT) {
                        const coordsFromCache = getUserGeoPositionFromCache();

                        if (coordsFromCache) {
                            setDistance(
                                getHaversineDistance(
                                    destinationLatitude,
                                    destinationLongitude,
                                    coordsFromCache.latitude,
                                    coordsFromCache.longitude
                                )
                            );
                            setDeviceCoords({ lat: coordsFromCache.latitude, lng: coordsFromCache.longitude });
                        }
                    } else {
                        setDistance(null);
                        setDeviceCoords(null);
                    }

                    setLoading(false);
                },
                options
            );
        }

        return () => {
            if (id) navigator.geolocation.clearWatch(id);
        };
    }, [destinationLatitude, destinationLongitude]);

    return { distance, loading, deviceCoords };
};

export default useWatchDistance;
