import React, { useCallback, useRef, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import classNames from 'classnames';

import styles from './navigationDropdown.module.css';
import useWindowResize from '../../../../common/hooks/useWindowResize';
import { useScreen } from '../../../../common/hooks/useScreen';
import { TOP_INDENT_DELTA } from './constants';

interface INavigationDropdownProps {
    children?: React.ReactNode;
    open?: boolean;
    onClose?: () => void;
    fullScreen?: boolean;
    className?: string;
    modalStyle?: React.CSSProperties;
    modalClassName?: string;
    disablePortal?: boolean;
    controlRef?: React.MutableRefObject<HTMLSpanElement>;
    navRef?: React.MutableRefObject<HTMLElement>;
    dataTestId?: string;
}

const NavigationDropdown = ({
    children,
    open,
    onClose,
    fullScreen = false,
    className,
    modalStyle,
    modalClassName,
    disablePortal,
    controlRef,
    navRef,
    dataTestId,
}: INavigationDropdownProps): JSX.Element => {
    const { isDesktop } = useScreen();
    const contentRef = useRef(null);
    const markerRef = useRef(null);
    const [leftIdent, setLeftIdent] = useState<number>(0);
    const [markerLeftIdent, setMarkerLeftIdent] = useState<number>(0);
    const [topIndent, setTopIndent] = useState<number>(0);

    const getIndents = useCallback(() => {
        const contentWidth = contentRef?.current?.clientWidth || 0;
        const controlWidth = controlRef?.current?.clientWidth || 0;
        const markerWidth = markerRef?.current?.offsetWidth || 0;
        const controlLeftIndent = controlRef?.current?.getBoundingClientRect().left;
        const controlMiddle = controlWidth / 2 + controlLeftIndent;
        const bodyWidth = document.body.clientWidth;
        let contentLeftIndent = controlMiddle - contentWidth / 2;

        if (bodyWidth < contentLeftIndent + contentWidth) contentLeftIndent = bodyWidth - contentWidth;

        const navHeight = navRef?.current?.clientHeight || 0;
        const navTopIndent = navRef?.current?.getBoundingClientRect().top;
        const markerHeight = markerRef?.current?.offsetHeight || 0;
        const contentTopIndent = navHeight + navTopIndent + markerHeight + TOP_INDENT_DELTA;

        const markerLeftIndent = controlLeftIndent + controlWidth / 2 - markerWidth / 2;

        return { contentLeftIndent, markerLeftIndent, contentTopIndent };
    }, [controlRef, navRef]);

    const isFullScreen = fullScreen && !isDesktop;
    const backdropProps = !isFullScreen ? { invisible: true } : undefined;

    const setIndents = useCallback(() => {
        if (!open) return;

        const { contentLeftIndent, markerLeftIndent, contentTopIndent } = getIndents();
        setLeftIdent(contentLeftIndent);
        setMarkerLeftIdent(markerLeftIndent);
        setTopIndent(contentTopIndent);
    }, [open, getIndents]);

    const handleWindowResize = useCallback(() => {
        setIndents();
    }, [setIndents]);

    const onRendered = () => {
        setIndents();
    };

    useWindowResize(handleWindowResize);

    return (
        <>
            <Modal
                open={open}
                hideBackdrop={isFullScreen}
                onClose={onClose}
                onRendered={onRendered}
                className={classNames(styles.modal, modalClassName)}
                disableScrollLock={!isFullScreen}
                BackdropProps={backdropProps}
                disablePortal={disablePortal ?? (!isDesktop && !isFullScreen)}
                style={modalStyle ?? (!isDesktop && !isFullScreen ? { position: 'static' } : undefined)}
                aria-label="modal"
                aria-modal="true"
            >
                <>
                    <div
                        className={classNames(
                            styles.content,
                            styles.contentDesktop,
                            'bannerTop',
                            {
                                [styles.isFullScreen]: isFullScreen,
                            },
                            className
                        )}
                        ref={contentRef}
                        data-testid={dataTestId}
                    >
                        {children}
                    </div>
                    <span className={classNames(styles.marker, 'marker')} ref={markerRef}></span>
                </>
            </Modal>
            <style jsx>{`
                .bannerTop {
                    top: ${topIndent + 10}px;
                    left: ${leftIdent}px;
                }
                .marker {
                    top: ${topIndent}px;
                    left: ${markerLeftIdent}px;
                }
            `}</style>
        </>
    );
};

export default NavigationDropdown;
