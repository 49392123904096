import { IPageLink } from '../../../@generated/@types/contentful';
import { DEFAULT_SIGNIN_TEXT } from './signIn/constants';

export enum NAV_LINKS {
    MENU = 'menu',
    DEALS = 'account/deals',
    LOCATIONS = 'locations',
    ORDERS = 'account/orders',
}

export const accountLink = {
    fields: {
        name: 'ACCOUNT',
        nameInUrl: 'account',
    },
} as IPageLink;

export const signinLink = {
    fields: {
        name: DEFAULT_SIGNIN_TEXT,
        nameInUrl: '/',
    },
} as IPageLink;

export const dealSignUpLink = {
    fields: {
        name: 'DEALS',
        nameInUrl: 'deals',
    },
} as IPageLink;
