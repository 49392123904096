import { useRouter } from 'next/router';

export const useLocationSearchUrl = () => {
    const { asPath } = useRouter();

    const searchLocationPath = '/locations';
    const isNoRedirectNeeded = !asPath || asPath.startsWith(searchLocationPath);
    const backToParam = isNoRedirectNeeded ? {} : { backTo: asPath };
    const backToParamString = new URLSearchParams(backToParam).toString();
    const locationSearchUrl = isNoRedirectNeeded ? searchLocationPath : `${searchLocationPath}?${backToParamString}`;

    return { locationSearchUrl, backToParam, searchLocationPath };
};
